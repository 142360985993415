.gifImagesuc{
    width: 50%;
    align-items: center;
    font-family: 'Nunito' !important;
}

.abovegif{
    display: flex;
    justify-content: center;
    /* margin-left: -27px; */
    font-family: 'Nunito' !important;
    margin-top: 10px;
}
.belowgif{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito' !important;
}
.congmessg{
    display: flex;
    align-items: center;
    gap:5px;
    font-family: 'Nunito' !important;

}
.thanksregis{
    font-family: 'Nunito' !important;
}
.maindownmatter{
    padding:20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    gap: 5px;
    font-size: 12px;
    font-family: 'Nunito' !important;
}

.belowMainmatterdiv{
    height:70vh;
    overflow-x: scroll;
}


.steps{
    font-weight: 600;
    font-size: 13px;
    font-family: 'Nunito' !important;
}
.muibuttondown{
    border-radius: 35px !important;
    font-size: 10px !important;
}

.css-6od3lo-MuiChip-label {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 17px !important;
    padding-right: 0px !important;
    white-space: nowrap;
}



