body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* body{
    font-family: "Nunito" !important;
}

.MuiInputAdornment-sizeSmall{
    font-size: 1px;
}

.css-1pnmrwp-MuiTypography-root {
    margin: 5px !important;
    margin-top: 8px !important;
    font-family: "Nunito" !important;
    
    font-size: .7rem !important;
    
}
 */


.css-ahj2mt-MuiTypography-root {
    font-family: "Nunito" !important;
    font-size: 12px !important;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.4375em !important
}
.gifImagesuc{
    width: 50%;
    align-items: center;
    font-family: 'Nunito' !important;
}

.abovegif{
    display: flex;
    justify-content: center;
    /* margin-left: -27px; */
    font-family: 'Nunito' !important;
    margin-top: 10px;
}
.belowgif{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito' !important;
}
.congmessg{
    display: flex;
    align-items: center;
    grid-gap:5px;
    gap:5px;
    font-family: 'Nunito' !important;

}
.thanksregis{
    font-family: 'Nunito' !important;
}
.maindownmatter{
    padding:20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    font-size: 12px;
    font-family: 'Nunito' !important;
}

.belowMainmatterdiv{
    height:70vh;
    overflow-x: scroll;
}


.steps{
    font-weight: 600;
    font-size: 13px;
    font-family: 'Nunito' !important;
}
.muibuttondown{
    border-radius: 35px !important;
    font-size: 10px !important;
}

.css-6od3lo-MuiChip-label {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 17px !important;
    padding-right: 0px !important;
    white-space: nowrap;
}




.appbarStyle {
    /* padding-top: 20px; */
    padding-bottom: 10px;
    border-radius: 0 0 34px 34px;
    /* margin-top: 10px; */
    background: #FAFAFA;
    font-family: "Nunito"
}

.firstbutton {
    margin-left: auto;
}

.navcustomername {
    margin-left: 15px !important;
    font-size: 12px;
}

.App {
    font-family: "Nunito" !important
}

.iconsonRight {
    margin-left: auto;
    display: flex;
    grid-gap: 2px;
    gap: 2px;
}

.mainHeaderOne {
    display: flex;
    flex-direction: column;
}

.mainHeaderTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mainDetails {
    display: flex;
    flex-direction: column;
}
.navbarMain {
    margin-left: 5px !important
}

.downNavbar {
    /* margin-top: 70px; */
    margin-bottom: 100px;
}

.LoginFormMain {
    margin-top: 80px !important;
}

.css-et1ao3-MuiTypography-root {
    font-family: 'Nunito' !important;
}
.cardMediaImagestyle{
    margin: 21px;
    border-radius: 15px
}
.cardmediaInsideImage{
    border-radius: 10px;
}



.css-46bh2p-MuiCardContent-root {
    padding: 0 16px 16px 16px !important;
   
}

.carousel-indicators [data-bs-target] {
    
    background-color: #fff !important;
   
}


