.navbarMain {
    margin-left: 5px !important
}

.downNavbar {
    /* margin-top: 70px; */
    margin-bottom: 100px;
}

.LoginFormMain {
    margin-top: 80px !important;
}

.css-et1ao3-MuiTypography-root {
    font-family: 'Nunito' !important;
}