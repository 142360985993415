/* body{
    font-family: "Nunito" !important;
}

.MuiInputAdornment-sizeSmall{
    font-size: 1px;
}

.css-1pnmrwp-MuiTypography-root {
    margin: 5px !important;
    margin-top: 8px !important;
    font-family: "Nunito" !important;
    
    font-size: .7rem !important;
    
}
 */


.css-ahj2mt-MuiTypography-root {
    font-family: "Nunito" !important;
    font-size: 12px !important;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.4375em !important
}