.cardMediaImagestyle{
    margin: 21px;
    border-radius: 15px
}
.cardmediaInsideImage{
    border-radius: 10px;
}



.css-46bh2p-MuiCardContent-root {
    padding: 0 16px 16px 16px !important;
   
}