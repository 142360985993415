.appbarStyle {
    /* padding-top: 20px; */
    padding-bottom: 10px;
    border-radius: 0 0 34px 34px;
    /* margin-top: 10px; */
    background: #FAFAFA;
    font-family: "Nunito"
}

.firstbutton {
    margin-left: auto;
}

.navcustomername {
    margin-left: 15px !important;
    font-size: 12px;
}

.App {
    font-family: "Nunito" !important
}

.iconsonRight {
    margin-left: auto;
    display: flex;
    gap: 2px;
}

.mainHeaderOne {
    display: flex;
    flex-direction: column;
}

.mainHeaderTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mainDetails {
    display: flex;
    flex-direction: column;
}